export function highlightPunctuation () {
  //find all elements with class "highlight-punctuation" and if the last character is a punctuation mark, wrap it in a span and add the class "text-taito-primary" to it

  const highlightPunctuationElements = document.querySelectorAll('.highlight-punctuation')
  highlightPunctuationElements.forEach((element) => {
    // console.log('element', element)
    const lastChar = element.innerHTML.trim().slice(-1)
    if (lastChar === '.' || lastChar === '!' || lastChar === '?') {
      element.innerHTML = element.innerHTML.trim().slice(0, -1) + '<span class="text-taito-primary">' + lastChar + '</span>'
    }
  }
  )
}
